<template>
  <div class="formkit-input">
    <div
      v-if="editor"
      class="formkit-toolbar grid grid-cols-[minmax(0,1fr),auto] gap-2"
    >
      <div class="flex flex-grow gap-2">
        <template v-if="showTextFormatting">
          <button
            v-for="(btn, index) in textFormatButtons"
            :key="index"
            type="button"
            class="formkit-toolbar-button"
            :class="{ 'is-active': editor.isActive(btn.command) }"
            :title="t(`btn.${btn.command}`)"
            @click="editor.chain().focus()[btn.action]().run()"
          >
            <Icon :name="btn.icon" />
          </button>
        </template>

        <template v-if="showLists">
          <button
            v-for="(btn, index) in listButtons"
            :key="index"
            type="button"
            class="formkit-toolbar-button"
            :class="{ 'is-active': editor.isActive(btn.command) }"
            :title="t(`btn.${btn.command}`)"
            @click="editor.chain().focus()[btn.action]().run()"
          >
            <Icon :name="btn.icon" />
          </button>
        </template>

        <button
          v-if="showLink"
          type="button"
          class="formkit-toolbar-button"
          :class="{ 'is-active': editor.isActive('link') }"
          :title="t('btn.link')"
          @click="setLink"
        >
          <Icon name="material-symbols:link" />
        </button>
      </div>

      <div class="flex gap-2">
        <button
          type="button"
          class="formkit-toolbar-button"
          :disabled="!editor.can().chain().focus().undo().run()"
          :title="t('btn.undo')"
          @click="editor.chain().focus().undo().run()"
        >
          <Icon name="material-symbols:undo" />
        </button>
        <button
          type="button"
          class="formkit-toolbar-button"
          :disabled="!editor.can().chain().focus().redo().run()"
          :title="t('btn.redo')"
          @click="editor.chain().focus().redo().run()"
        >
          <Icon name="material-symbols:redo" />
        </button>
      </div>
    </div>
    <EditorContent :editor="editor" class="formkit-input formkit-background" />
  </div>
</template>

<script setup lang="ts">
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import type { FormKitFrameworkContext } from '@formkit/core'
import type { WysiwygEditorConfig } from '@forms/formkit/inputs/wysiwyg'

interface Props {
  context: FormKitFrameworkContext
}

const props = defineProps<Props>()
const { t } = useI18n()

const editorConfig = computed<WysiwygEditorConfig>(
  () =>
    props.context.node.props.editorConfig || {
      extensions: ['text', 'list', 'link'],
    }
)

const showTextFormatting = computed(
  () => editorConfig.value.extensions?.includes('text') ?? true
)
const showLists = computed(
  () => editorConfig.value.extensions?.includes('list') ?? true
)
const showLink = computed(
  () => editorConfig.value.extensions?.includes('link') ?? true
)

const textFormatButtons = [
  {
    command: 'bold',
    action: 'toggleBold',
    icon: 'material-symbols:format-bold',
  },
  {
    command: 'italic',
    action: 'toggleItalic',
    icon: 'material-symbols:format-italic',
  },
  {
    command: 'underline',
    action: 'toggleUnderline',
    icon: 'material-symbols:format-underlined',
  },
  {
    command: 'strike',
    action: 'toggleStrike',
    icon: 'material-symbols:format-strikethrough',
  },
]

const listButtons = [
  {
    command: 'bulletList',
    action: 'toggleBulletList',
    icon: 'material-symbols:format-list-bulleted',
  },
  {
    command: 'orderedList',
    action: 'toggleOrderedList',
    icon: 'material-symbols:format-list-numbered',
  },
]

const editor = useEditor({
  content: props.context.node._value ?? '',
  extensions: [
    StarterKit,
    Underline,
    Link.configure({
      openOnClick: false,
      validate: (href) => /^(https?:\/\/|\/|\.\/|\.\.\/)/.test(href),
      HTMLAttributes: {
        rel: null,
        target: null,
      },
    }),
  ],
  injectCSS: false,
  onUpdate: ({ editor }) => {
    const html = editor.getHTML()
    props.context.node.input(html)
  },
})

const setLink = () => {
  const url = window.prompt('URL')

  if (url === null) {
    return
  }

  if (url === '') {
    editor.value?.chain().focus().extendMarkRange('link').unsetLink().run()
    return
  }

  const attributes = {
    href: url,
    ...(url.startsWith('http') && {
      target: '_blank',
      rel: 'noopener noreferrer',
    }),
  }

  editor.value
    ?.chain()
    .focus()
    .extendMarkRange('link')
    .setLink(attributes)
    .run()
}
</script>

<i18n>
de:
  btn:
    bold: Fett
    italic: Kursiv
    underline: Unterstrichen
    strike: Durchgestrichen
    bulletList: Aufzählungsliste
    orderedList: Nummerierte Liste
    link: Link
    undo: Rückgängig
    redo: Wiederholen
</i18n>
