import { createValidationPlugin } from '@formkit/validation'
import {
  email as emailRule,
  required as requiredRule,
  confirm as confirmRule,
  contains_numeric as containsNumericRule,
  contains_symbol as containsSymbolRule,
  contains_uppercase as containsUppercaseRule,
  contains_lowercase as containsLowercaseRule,
  contains_alphanumeric as containsAlphanumericRule,
  length as lengthRule,
  between as betweenRule,
} from '@formkit/rules'
import validateCreditCard from '@booking/utils/validate-credit-card'

function validExpirationDate(node) {
  const value = node.value

  if (!value || value.length !== 6) return false

  const month = parseInt(value.substring(0, 2), 10)
  const year = parseInt(value.substring(2, 6), 10)

  if (isNaN(month) || isNaN(year) || month < 1 || month > 12) return false

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1

  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false
  }

  return true
}

function visaOrMastercard(node) {
  const { isValid } = validateCreditCard(node.value)
  return isValid
}

export const validation = createValidationPlugin({
  required: requiredRule,
  confirm: confirmRule,
  contains_numeric: containsNumericRule,
  contains_symbol: containsSymbolRule,
  contains_uppercase: containsUppercaseRule,
  contains_lowercase: containsLowercaseRule,
  contains_alphanumeric: containsAlphanumericRule,
  email: emailRule,
  length: lengthRule,
  between: betweenRule,
  validExpirationDate,
  visaOrMastercard,
})
