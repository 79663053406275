import { createInput } from '@formkit/vue'
import type { FormKitTypeDefinition } from '@formkit/core'
import TipTapEditor from '@forms/components/tip-tap-editor.vue'

export const wysiwygInput = createInput(TipTapEditor)

export interface WysiwygEditorConfig {
  extensions?: Array<'text' | 'list' | 'link'>
}

export const wysiwygInputSchema: FormKitTypeDefinition = {
  ...wysiwygInput,
  props: ['label', 'help', 'editorConfig'],
  forceTypeProp: 'wysiwyg',
}
