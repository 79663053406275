export const CREDIT_CARD_REGEX = {
  MASTERCARD:
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
} as const

export type CardType = keyof typeof CREDIT_CARD_REGEX | ''

export default function validateCreditCard(cardNumber: string): {
  isValid: boolean
  type: CardType
} {
  const cleanNumber = cardNumber.replace(/\s+/g, '')

  if (CREDIT_CARD_REGEX.MASTERCARD.test(cleanNumber)) {
    return { isValid: true, type: 'MASTERCARD' }
  }

  if (CREDIT_CARD_REGEX.VISA.test(cleanNumber)) {
    return { isValid: true, type: 'VISA' }
  }

  return { isValid: false, type: '' }
}
